import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';

const specificFieldsForResetPassword = `
	email
	xid
`;

const commonFields = `
	id
	createdAt
	updatedAt
	phone
	password
	licenseOwner
	projectTitle
	validated
	validatedOn
	language
	userId
	timeZone
	requestType
	userName
	userFirstName
	userTypeForFilm
	nextProductionDate
	budgetBracket
	emailValidationCode
	${specificFieldsForResetPassword}
`;

export const checkLicenseEmailValidationCode = async (licenseId, code) => {
	try {
		const result = await apollo.mutate({
			mutation: gql`
				mutation CheckLicenseEmailValidationCode($LicenseId: ID!, $Code: String!) {
					CheckLicenseEmailValidationCode(LicenseId: $LicenseId, Code: $Code)
				}
			`,
			variables: {
				LicenseId: licenseId,
				Code: code
			}
		});
		return result.data;
	} catch (error) {
		return;
	}
};

export const emailValidationCode = async (licenseRequest) => {
	if (isNil(licenseRequest)) return;
	try {
		const {
			data: { EmailValidationCode }
		} = await apollo.mutate({
			mutation: gql`
				mutation EMAIL_VALIDATION_CODE($newLicenseRequest: LicenseRequestInput!) {
					EmailValidationCode(NewLicenseRequest: $newLicenseRequest) {
						id
						createdAt
						updatedAt
						email
						phone
						password
						licenseOwner
						projectTitle
						xid
						validated
						validatedOn
						language
						userId
						timeZone
						requestType
						userName
						userFirstName
						userTypeForFilm
						nextProductionDate
						budgetBracket
						emailValidationProcess
					}
				}
			`,
			variables: {
				newLicenseRequest: licenseRequest
			},
			fetchPolicy: 'no-cache'
		});

		return {
			...EmailValidationCode,
			id: parseInt(EmailValidationCode.id)
		};
	} catch (e) {
		console.log({ e });
	}
};

export const checkLicensePhoneValidationCode = async (licenseId, code) => {
	const result = await apollo.mutate({
		mutation: gql`
			mutation CheckLicensePhoneValidationCode($LicenseId: ID!, $Code: String!) {
				CheckLicensePhoneValidationCode(LicenseId: $LicenseId, Code: $Code)
			}
		`,
		variables: {
			LicenseId: +licenseId,
			Code: code
		}
	});

	return result.data;
};

export const phoneValidationCode = async (licenseRequest) => {
	if (isNil(licenseRequest)) return;
	try {
		const {
			data: { PhoneValidationCode }
		} = await apollo.mutate({
			mutation: gql`
				mutation PHONE_CODE_VALIDATION($newLicenseRequest: LicenseRequestInput!) {
					PhoneValidationCode(NewLicenseRequest: $newLicenseRequest) {
						id
						createdAt
						updatedAt
						email
						phone
						password
						licenseOwner
						projectTitle
						xid
						validated
						validatedOn
						language
						userId
						timeZone
						requestType
						userName
						userFirstName
						userTypeForFilm
						nextProductionDate
						budgetBracket
						phoneValidationCode
						userTypeDesc
						languageDesc
					}
				}
			`,
			variables: {
				newLicenseRequest: licenseRequest
			},
			fetchPolicy: 'no-cache'
		});

		return {
			...PhoneValidationCode,
			id: parseInt(PhoneValidationCode.id)
		};
	} catch (e) {
		console.log({ e });
	}
};

/**
 * Method service to send email verification for passowrd reset.
 * @param {String} email
 * @return {Promise|null}
 */
export const emailVerificationForPasswordReset = async (email) => {
	if (isNil(email)) return;

	const mutationGql = gql`
		mutation RESET_PASSWORD_REQUEST($email: String!) {
			ResetPasswordRequest(NewLicenseRequest: { email: $email }) {
				${specificFieldsForResetPassword}
			}
		}
	`;
	return await apollo.mutate({
		mutation: mutationGql,
		variables: {
			email: email
		}
	});
};

/**
 * Method service for signin.
 * @param {String} email
 * @param {String} password
 * @param {String} xid
 * @param {String} urXid
 * @param {Boolean} createProject
 * @return {Promise}
 */
export const signinService = async (email, password, xid = '', urXid = '', createProject = false, otpCode = null, licenseId = null, token = null) => {
	const mutationGql = gql`
		mutation SignIn($email: String!, $password: String!, $xid: String, $urXid: String, $createProject: Boolean, $otpCode: String, $licenseId: ID) {
			signIn(email: $email, password: $password, xid: $xid, UrXid: $urXid, CreateProject: $createProject, OtpCode: $otpCode, LicenseId: $licenseId)
		}
	`;
	try {
		return await apollo.mutate({
			mutation: mutationGql,
			variables: {
				email: email,
				password: password,
				xid: xid,
				urXid: urXid,
				createProject: createProject,
				otpCode: otpCode,
				licenseId: licenseId
			},
			context: {
				Authorization: `Bearer ${token}`
			}
		});
	} catch (error) {}
};

export const verifyRecaptcha = async (tokenRecaptcha) => {
	const mutationGql = gql`
		query VerifyRecaptcha($tokenRecaptcha: String!) {
			VerifyRecaptcha(TokenRecaptcha: $tokenRecaptcha)
		}
	`;
	return await apollo.mutate({
		mutation: mutationGql,
		variables: {
			tokenRecaptcha: tokenRecaptcha
		}
	});
};
